export const SWAP_GAS_LIMIT = 512500
export const ADD_LIQUIDITY_GAS_LIMIT = 662500
export const CREATE_PAIR_GAS_LIMIT = 5125000
export const HOP_ADDITIONAL_GAS = 175000
export const REMOVE_LIQUIDITY_GAS_LIMIT = 625000

// ARBITRUM_GAS_LIMIT
export const ARBITRUM_SWAP_GAS_LIMIT = 4000000
export const ARBITRUM_ADD_LIQUIDITY_GAS_LIMIT = 4000000
export const ARBITRUM_CREATE_PAIR_GAS_LIMIT = 9000000
export const ARBITRUM_HOP_ADDITIONAL_GAS = 600000
export const ARBITRUM_REMOVE_LIQUIDITY_GAS_LIMIT = 6000000
